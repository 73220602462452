import { createContext, FC, PropsWithChildren, useContext, useState } from "react";

interface AccordionContextProps {
	idsActiveItems?: string[];
	changeActiveItem?: (index: string) => void;
}
interface AccordionContextProviderProps {
	multiple?: boolean;
	onChange?: () => string[];
}

export const AccordionContext = createContext<AccordionContextProps>({});
export const AccordionContextProvider: FC<PropsWithChildren<AccordionContextProviderProps>> = ({
	children,
	multiple,
	onChange,
}) => {
	const [idsActiveItems, setIdsActiveItems] = useState<string[]>([]);
	const changeActiveItem = (index: string): void => {
		let _idsActiveItems = [];
		if (idsActiveItems.includes(index)) {
			_idsActiveItems = idsActiveItems.filter((value) => {
				return value !== index;
			});
		}
		if (!idsActiveItems.includes(index) && !multiple) {
			_idsActiveItems = [index];
		}
		if (!idsActiveItems.includes(index) && multiple) {
			_idsActiveItems = [...idsActiveItems, index];
		}
		if (onChange) {
			onChange();
		}
		setIdsActiveItems(_idsActiveItems);
	};
	return (
		<AccordionContext.Provider value={{ idsActiveItems, changeActiveItem }}>{children}</AccordionContext.Provider>
	);
};
export const useAccordionContext = (): AccordionContextProps => {
	return useContext<AccordionContextProps>(AccordionContext);
};
