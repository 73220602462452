import { ArticleJsonLd, NextSeo } from "next-seo";
import { NextSeoProps } from "next-seo/lib/types";
import { useRouter } from "next/router";
import { FC } from "react";
import { getOgImageUrl } from "./getOgImageUrl";

interface DynamicSeoProps {
	title: string;
	description?: string;
	subtitle?: string;
	type?: "article";
	imageSrc?: string;
	datePublished?: string;
	dateModified?: string;
}

export const DynamicSeo: FC<DynamicSeoProps> = ({
	type,
	datePublished,
	dateModified,
	imageSrc,
	title = "PATRICK DITTRICH",
	description,
	subtitle = null,
}) => {
	const router = useRouter();
	const seoProps: NextSeoProps = {
		title: title,
		description: description,
		openGraph: {
			title,
			description,
			images: [
				{
					url: getOgImageUrl({ title, content: description, subtitle }),
					width: 1200,
					height: 630,
					alt: title,
				},
			],
		},
	};

	return (
		<>
			<NextSeo {...seoProps} />
			{type === "article" && (
				<ArticleJsonLd
					type={"Article"}
					url={process.env.NEXT_PUBLIC_BASE_URL + router.asPath}
					title={title}
					images={[imageSrc]}
					datePublished={datePublished}
					dateModified={dateModified}
					authorName={"Patrick Dittrich"}
					description={description}
				/>
			)}
		</>
	);
};
