import { createContext, FC, PropsWithChildren, useContext, useEffect, useId, useState } from "react";
import { useAccordionContext } from "./AccordionProvider";

interface AccordionItemContextProps {
	id?: string;
	isActive?: boolean;
	setIsActive?: (index: boolean) => void;
}

export const AccordionItemContext = createContext<AccordionItemContextProps>({});
export const AccordionItemContextProvider: FC<PropsWithChildren> = ({ children }) => {
	const { idsActiveItems } = useAccordionContext();
	const [isActive, setIsActive] = useState<boolean>(false);
	const [isDisabled, setIsDisabled] = useState<boolean>(false);
	const [id, setId] = useState<string>(useId());
	useEffect(() => {
		if (idsActiveItems.includes(id)) {
			setIsActive(true);
		} else {
			setIsActive(false);
		}
	}, [idsActiveItems]);

	return (
		<AccordionItemContext.Provider value={{ id, isActive, setIsActive }}>{children}</AccordionItemContext.Provider>
	);
};
export const useAccordionItemContext = () => {
	return useContext<AccordionItemContextProps>(AccordionItemContext);
};
