import axios from "axios";
import Image from "next/legacy/image";
import Link from "next/link";
import { FC, useEffect, useState } from "react";
import styles from "./SpotifyBanner.module.scss";

export const SpotifyBanner: FC = () => {
	const [currentPlaying, setCurrentPlaying] = useState(null);
	useEffect(() => {
		const interval = setInterval(async () => {
			try {
				const { data } = await axios.get("/api/listening");
				if (data?.song?.is_playing) {
					setCurrentPlaying(data);
				} else {
					setTimeout(() => {
						setCurrentPlaying(null);
					}, 500);
				}
			} catch (e) {
				setTimeout(() => {
					setCurrentPlaying(null);
				}, 500);
			}
		}, 5000);
		return () => clearInterval(interval);
	}, []);
	return (
		<>
			{currentPlaying && (
				<Link href={currentPlaying.song.item.uri} className={styles.banner} target={"_blank"} data-nosnippet="">
					<strong className={styles.title} data-nosnippet="">
						music:{" "}
					</strong>
					<div className={styles.imageContainer}>
						<Image
							src={currentPlaying.song.item.album.images[0].url}
							width={25}
							unoptimized={true}
							height={25}
							alt={"cover image"}
							className={styles.image}
						/>
					</div>
					<div className={styles.content} data-nosnippet="">
						<strong data-nosnippet="" className={styles.songTitle}>
							{" "}
							{currentPlaying.song.item.name}
						</strong>
						<small className={styles.subLabel}>
							{currentPlaying.song.item.artists.map((value, index) => value.name).join(",")}
						</small>
					</div>
					<div className={styles.animationContainer}>
						<svg xmlns="http://www.w3.org/2000/svg" className={styles.equilizer} viewBox="0 0 128 128">
							<g>
								<title>Audio Equilizer</title>
								<rect className={styles.bar} transform="translate(0,0)" y="10" rx="5" ry="5" />
								<rect className={styles.bar} transform="translate(20,0)" y="10" rx="5" ry="5" />
								<rect className={styles.bar} transform="translate(40,0)" y="10" rx="5" ry="5" />
								<rect className={styles.bar} transform="translate(60,0)" y="10" rx="5" ry="5" />
								<rect className={styles.bar} transform="translate(80,0)" y="10" rx="5" ry="5" />
							</g>
						</svg>
					</div>
				</Link>
			)}
		</>
	);
};
