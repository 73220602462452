import { FC } from "react";
import { Paragraph } from "../Paragraph";
import { Section } from "../Section";
import { SpotifyBanner } from "../SpotifyBanner";

export const Footer: FC = () => {
	return (
		<footer>
			<Section horizontalAlignment={"center"}>
				<SpotifyBanner />
				<Paragraph textCenter={true}>Copyright © 2022 Patrick Dittrich</Paragraph>
			</Section>
		</footer>
	);
};
