import classNames from "classnames";
import {FC, PropsWithChildren} from "react";
import { Headline } from "../Headline";
import { Icon } from "../Icon";
import { Paragraph } from "../Paragraph";
import styles from "./Note.module.scss";
interface NoteProps {
	title?: string;
	mode?: "info" | "warning" | "alert";
}
export const Note: FC<PropsWithChildren< NoteProps>> = ({ children, title, mode = "info" }) => {
	let _classNames = classNames(styles.note, {
		[styles.alert]: mode === "alert",
		[styles.warning]: mode === "warning",
	});
	return (
		<div className={_classNames} role="note">
			<Icon icon={mode} className={styles.icon} />
			<div className={styles.content}>
				{title && <Headline level={4}>{title}</Headline>}
				{children && <Paragraph>{children}</Paragraph>}
			</div>
		</div>
	);
};
