import { NextPage } from "next";
import { useEffect, useRef, useState } from "react";
import { DynamicSeo } from "seo";
import {Button, Card, CardGrid, Headline, Paragraph, Section} from "../components";
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemPanel } from "../components/Accordion";

export const StyleguideModule: NextPage = () => {
	const ref = useRef(null);
	const [scrollSpyList, setScrollSpyList] = useState<string[]>([]);
	useEffect(() => {
		setScrollSpyList([...ref.current.querySelectorAll("[data-scroll-spy]")].map((value) => value.id));
		return () => {};
	}, []);
	return (
		<div ref={ref}>
			<DynamicSeo title={"Styleguide"} />
			<Section fullWidth={true} horizontalAlignment={"center"} verticalAlignment={"center"} fullHeight={true}>
				<Headline level={1}>Styleguide</Headline>
			</Section>

			<Section contentFullWidth={true} data-scroll-spy id={"TYPO"}>
				<Headline level={2}>TYPO</Headline>
				<Headline level={1}>Heading h1</Headline>
				<Headline level={2}>Heading h2</Headline>
				<Headline level={3}>Heading h3</Headline>
				<Headline level={4}>Heading h4</Headline>
				<Headline level={5}>Heading h5</Headline>
				<Headline level={6}>Heading h6</Headline>
				<Paragraph>Paragraph</Paragraph>
			</Section>
			<Section contentFullWidth={true}>
				<Headline level={2}>Buttons</Headline>
				<Button>Button</Button>
				<Button variant={"link"}>Link button</Button>
			</Section>
			<Section contentFullWidth={true}>
				<Headline level={2}>Accordion</Headline>
				<Headline level={3}>Accordion (default)</Headline>
				<Accordion>
					<AccordionItem>
						<AccordionItemHeading>Accordion Title 1</AccordionItemHeading>
						<AccordionItemPanel>Content of Item 1</AccordionItemPanel>
					</AccordionItem>
					<AccordionItem>
						<AccordionItemHeading>Accordion Title 2</AccordionItemHeading>
						<AccordionItemPanel>Content of Item 2</AccordionItemPanel>
					</AccordionItem>
					<AccordionItem>
						<AccordionItemHeading>Accordion Title 3</AccordionItemHeading>
						<AccordionItemPanel>Content of Item 3</AccordionItemPanel>
					</AccordionItem>
					<AccordionItem>
						<AccordionItemHeading>Accordion Title 4</AccordionItemHeading>
						<AccordionItemPanel>Content of Item 4</AccordionItemPanel>
					</AccordionItem>
				</Accordion>
				<Headline level={3}>Accordion (multiple)</Headline>

				<Accordion multiple={true}>
					<AccordionItem>
						<AccordionItemHeading>Accordion Title 1</AccordionItemHeading>
						<AccordionItemPanel>Content of Item 1</AccordionItemPanel>
					</AccordionItem>
					<AccordionItem>
						<AccordionItemHeading>Accordion Title 2</AccordionItemHeading>
						<AccordionItemPanel>Content of Item 2</AccordionItemPanel>
					</AccordionItem>
					<AccordionItem>
						<AccordionItemHeading>Accordion Title 3</AccordionItemHeading>
						<AccordionItemPanel>Content of Item 3</AccordionItemPanel>
					</AccordionItem>
					<AccordionItem>
						<AccordionItemHeading>Accordion Title 4</AccordionItemHeading>
						<AccordionItemPanel>Content of Item 4</AccordionItemPanel>
					</AccordionItem>
				</Accordion>
			</Section>
			<Section contentFullWidth={true}>
				<Headline level={2}>Cards</Headline>
				<CardGrid>
					{

					}
					<Card href={"#"} headerLabel={"Header label"} title={"Card title"} subTitle={"Card subtitle"} imageAlt={"Card image"} imageSrc={"/assets/card-example.jpeg"}></Card>
				</CardGrid>
			</Section>
		</div>
	);
};
