import classNames from "classnames";
import Link from "next/link";
import { useRouter } from "next/router";
import {ComponentProps, forwardRef, Ref} from "react";
import { Icon, IconSelection } from "../Icon";
import styles from "./Button.module.scss";

type ButtonProps = ComponentProps<"a"> &
	ComponentProps<"button"> & {
		variant?: "primary" | "link" | "blank";
		activeClass?: string;
		icon?: IconSelection;
		iconSlot?: "start" | "end";
		iconOnly?: boolean;
		noSpacing?: boolean;
		customColor?: string;
		fullWidth?: boolean;
		circle?: boolean;
	};

export const Button = forwardRef<HTMLLinkElement & HTMLButtonElement & HTMLAnchorElement, ButtonProps>(
	(
		{
			children,
			className,
			customColor = null,
			variant,
			href,
			activeClass = "",
			icon = null,
			iconSlot = null,
			iconOnly = false,
			noSpacing = false,
			circle = false,
			fullWidth = false,
			style,
			...rest
		},
		ref
	) => {
		const router = useRouter();
		let _classNames = classNames(className, activeClass, {
			activeClass: router.asPath === href,
			[styles.button]: variant !== "link",
			[styles.primary]: variant === "primary",
			[styles.blank]: variant === "blank",
			[styles.iconOnly]: iconOnly,
			[styles.fullWidth]: fullWidth,
			[styles.noSpacing]: noSpacing,
			[styles.circle]: circle,
		});

		let styleProps = style ?? {};
		if (customColor) styleProps["--button-background"] = customColor;
		if (iconOnly || iconSlot === "start")
			children = (
				<>
					<Icon icon={icon} />
					{children}
				</>
			);
		if (iconSlot === "end") {
			children = (
				<>
					{children}
					<Icon icon={icon} />
				</>
			);
		}
		if (href) {
			const { items } = { ...rest } as { items: ComponentProps<"a"> };
			return (
				<Link
					passHref={true}
					href={href}
					className={_classNames}
					role={"button"}
					style={styleProps}
					/* @ts-ignore */
					ref={ref}
					{...items}>
					{children}
				</Link>
			);
		} else {
			return (
				<button className={_classNames} role={"button"} style={styleProps} ref={ref} {...rest}>
					{children}
				</button>
			);
		}
	}
);
