import Link from "next/link";
import { useRouter } from "next/router";
import { FC, useEffect, useRef, useState } from "react";
import { useLayoutContext } from "../../provider";
import { ThemeToggle } from "../../statics";
import { Logo } from "../Logo";
import { Navigation } from "../Navigation";
import styles from "./Header.module.scss";

export const Header: FC = () => {
	const [headerContentHeight, setHeaderContentHeight] = useState(null);
	const [scrollDistance, setScrollDistance] = useState(0);
	const [atTop, setAtTop] = useState(true);
	const [hideHeader, setHideHeader] = useState(false);
	const { mobileMenuOpen, windowSize } = useLayoutContext();
	const router = useRouter();

	const ref = useRef<HTMLDivElement>(null);
	useEffect(() => {
		const handleScroll = () => {
			if (mobileMenuOpen) return;
			if (window.scrollY > scrollDistance) {
				setAtTop(false);
				setHideHeader(true);
				setScrollDistance(window.scrollY);
				return;
			}
			if (window.scrollY < scrollDistance) {
				setAtTop(false);
				setHideHeader(false);
				setScrollDistance(window.scrollY);
				return;
			}
			if (window.scrollY < headerContentHeight) {
				setAtTop(true);
				setHideHeader(false);
				setScrollDistance(window.scrollY);
				return;
			}
		};
		window.addEventListener("scroll", handleScroll);
		handleScroll();
		setHeaderContentHeight(ref.current.clientHeight);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, [scrollDistance, mobileMenuOpen]);

	return (
		<header style={{ height: headerContentHeight + "px" }}>
			<div className={styles.container} data-hide={hideHeader} data-at-top={atTop} ref={ref}>
				<div className={styles.content}>
					<Link
						href={"/"}
						passHref={true}
						className={styles.link}
						aria-label={"home"}
						onContextMenu={(e) => {
							e.preventDefault();
							e.stopPropagation();
							router.push("/design");
						}}>
						<Logo size={"sm"} />
					</Link>
					<Navigation />
					{windowSize.width > 768 && <ThemeToggle />}
				</div>
			</div>
		</header>
	);
};
