import Script from "next/script";
import { useEffect, useState } from "react";

export const ScriptsGlobal = () => {
	const [loadAnalytics, setLoadAnalytics] = useState(false);
	useEffect(() => {
		setTimeout(() => {
			setLoadAnalytics(true);
		}, 3000);
	}, []);
	return (
		<>
			<Script id={"cookie-script"}>
				{`var cpm = {};(function(h,u,b){ var d=h.getElementsByTagName("script")[0],e=h.createElement("script"); e.async=true;e.src='https://cookiehub.net/c2/eca1f8b9.js'; e.onload=function(){u.cookiehub.load(b);};d.parentNode.insertBefore(e,d);})(document,window,cpm);`}
			</Script>
			{loadAnalytics && (
				<>
					<Script
						id={"gtm"}
						strategy="lazyOnload"
						src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
					/>
					<Script id="google-analytics" strategy="lazyOnload">
						{`window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {page_path: window.location.pathname,});`}
					</Script>
				</>
			)}
		</>
	);
};
