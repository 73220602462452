import {FC, PropsWithChildren} from "react";
import styles from "./Quote.module.scss";
interface QuoteProps {
	author?: string;
}
export const Quote: FC<PropsWithChildren< QuoteProps>> = ({ children, author }) => {
	return (
		<blockquote>
			<p className={styles.quote}>{children}</p>
			{author && <cite title={author}>-- {author}</cite>}
		</blockquote>
	);
};
