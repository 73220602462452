import { FC } from "react";
import { Button } from "../../components";
import { useThemeContext } from "../../provider";
const ThemeIconMapping = {
	dark: "sun",
	light: "moon",
};
const ThemeToggleMapping = {
	dark: "light",
	light: "dark",
};
export const ThemeToggle: FC = () => {
	const { theme, changeTheme } = useThemeContext();
	return (
		<Button
			onClick={() => changeTheme(ThemeToggleMapping[theme])}
			icon={ThemeIconMapping[theme]}
			variant={"blank"}
			iconOnly={true}
			noSpacing={true}
			aria-label={"Toggle theme"}
		/>
	);
};
