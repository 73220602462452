import { useRouter } from "next/router";
import { createContext, FC, PropsWithChildren, useContext, useEffect, useState } from "react";
type BreakPointState = "sm" | "md" | "lg" | "xl" | "xxl" | string;
interface LayoutContextProps {
	breakpoint?: BreakPointState;
	toggleMobileMenu?: (state: boolean) => void;
	mobileMenuOpen?: boolean;
	windowSize?: {
		width: number | undefined;
		height: number | undefined;
	};
}
const breakPoints = [
	{
		label: "sm",
		value: 568,
	},
	{
		label: "md",
		value: 768,
	},
	{
		label: "lg",
		value: 992,
	},
	{
		label: "xl",
		value: 1200,
	},
	{
		label: "xxl",
		value: 1400,
	},
];
export const LayoutContext = createContext<LayoutContextProps>({});
export const LayoutContextProvider: FC<PropsWithChildren> = ({ children }) => {
	const [breakpoint, setBreakpoint] = useState<BreakPointState>(null);
	const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
	const [windowSize, setWindowSize] = useState({
		width: undefined,
		height: undefined,
	});
	const toggleMobileMenu = (state: boolean) => {
		setMobileMenuOpen(state);
	};
	const router = useRouter();

	useEffect(() => {
		const handleResize = () => {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		};
		const handleRouteChange = () => {
			setMobileMenuOpen(false);
		};
		window.addEventListener("resize", handleResize);
		router.events.on("routeChangeStart", handleRouteChange);
		handleResize();
		return () => {
			window.removeEventListener("resize", handleResize);
			router.events.off("routeChangeStart", handleRouteChange);
		};
	}, []);
	useEffect(() => {
		let breakpointAttribute = breakPoints[0].label;
		for (const { label, value } of breakPoints) {
			if (value > windowSize.width) {
				breakpointAttribute = label;
				break;
			}
		}
		setBreakpoint(breakpointAttribute);
	}, [windowSize]);
	useEffect(() => {
		document.documentElement.dataset.menuOpen = mobileMenuOpen.toString();
	}, [mobileMenuOpen]);

	return (
		<LayoutContext.Provider value={{ breakpoint, windowSize, toggleMobileMenu, mobileMenuOpen }}>
			{children}
		</LayoutContext.Provider>
	);
};
export const useLayoutContext = () => {
	return useContext<LayoutContextProps>(LayoutContext);
};
