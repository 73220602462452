import classNames from "classnames";
import { ComponentProps, FC } from "react";
import styles from "./Section.module.scss";
interface SectionProps extends ComponentProps<"section"> {
	fullWidth?: boolean;
	verticalAlignment?: "start" | "center" | "end" | string;
	horizontalAlignment?: "start" | "center" | "end" | string;
	fullHeight?: boolean;
	contentFullWidth?: boolean;
	noHorizontalSpacing?: boolean;
	maxWidth?: "sm" | "lg" | "xl" | null | string;
}

export const Section: FC<SectionProps> = ({
	children,
	horizontalAlignment,
	fullWidth = false,
	verticalAlignment,
	fullHeight = false,
	maxWidth = null,
	contentFullWidth = false,
	noHorizontalSpacing = false,
	...rest
}) => {
	let _classNames = classNames(
		{
			[styles.hstart]: horizontalAlignment !== "start",
			[styles.hcenter]: horizontalAlignment === "center",
			[styles.hend]: horizontalAlignment === "end",
			[styles.vstart]: verticalAlignment !== "start",
			[styles.vcenter]: verticalAlignment === "center",
			[styles.vend]: verticalAlignment === "end",
			[styles.fullHeight]: fullHeight,
			[styles.fullWidth]: fullWidth,
			[styles.sm]: maxWidth === "sm",
			[styles.lg]: maxWidth === "lg",
			[styles.xl]: maxWidth === "xl",
			[styles.noHorizontalSpacing]: noHorizontalSpacing,
		},
		styles.section
	);
	let _contentClassNames = classNames(styles.content, {
		[styles.contentFullWidth]: contentFullWidth,
	});
	return (
		<section className={_classNames} {...rest}>
			<div className={_contentClassNames}>{children}</div>
		</section>
	);
};
