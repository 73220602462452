interface ogImageParams {
	title?: string;
	subtitle?: string;
	content?: string;
}
export const getOgImageUrl = ({ title, subtitle = "Patrick Dittrich", content }: ogImageParams) => {
	let ogImageParams: ogImageParams = {};
	if (title) ogImageParams.title = title;
	if (subtitle) ogImageParams.subtitle = subtitle;
	if (content) ogImageParams.content = content;
	// @ts-ignore
	return process.env.NEXT_PUBLIC_BASE_URL + "/api/og-image?" + new URLSearchParams(ogImageParams).toString();
};
