import { FC, PropsWithChildren } from "react";
import { AccordionItemContextProvider } from "../../provider/AccordionItemProvider";
import styles from "./AccordionItem.module.scss";
export const AccordionItem: FC<PropsWithChildren> = ({ children }) => {
	return (
		<AccordionItemContextProvider>
			<div className={styles.item}>{children}</div>
		</AccordionItemContextProvider>
	);
};
