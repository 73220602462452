import Head from "next/head";
export const HeadGlobal = () => {
	return (
		<Head>
			<link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
			<link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
			<link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
			<link rel="manifest" href="/site.webmanifest" />
			<link rel="mask-icon" href="/safari-pinned-tab.svg" color="#000000" />
			<meta name="msapplication-TileColor" content="#000000" />
			<meta name="theme-color" content="#ffffff" />
			<meta name="viewport" content="width=device-width, initial-scale=1.0, viewport-fit=cover" />
			<meta name="theme-color" content="#000000" />
			<meta name="apple-mobile-web-app-status-bar-style" content="#000000" />
			<meta name="viewport" content="width=device-width, initial-scale=1.0" />
		</Head>
	);
};
