import {createContext, FC, PropsWithChildren, useContext, useEffect, useState} from "react";

const STORAGE_THEME_KEY = "theme";
type ThemeState = "dark" | "light" | string;

interface ThemeContextProps {
	theme?: ThemeState;
	changeTheme?: (theme: ThemeState) => void;
}

export const ThemeContext = createContext<ThemeContextProps>({});
export const ThemeContextProvider: FC<PropsWithChildren> = ({ children }) => {
	const [theme, setTheme] = useState<ThemeState>(null);
	const changeTheme = (theme: ThemeState): void => {
		setTheme(theme);
		localStorage.setItem(STORAGE_THEME_KEY, theme);
	};
	useEffect(() => {
		let storedTheme = localStorage.getItem(STORAGE_THEME_KEY);
		if (storedTheme && storedTheme != theme) {
			setTheme(storedTheme);
		} else if (window?.matchMedia) {
			if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
				setTheme("dark");
			} else {
				setTheme("light");
			}
		} else {
			setTheme("light");
		}
	}, []);
	useEffect(() => {
		document.documentElement.dataset.theme = theme;
	}, [theme]);
	return <ThemeContext.Provider value={{ theme, changeTheme }}>{children}</ThemeContext.Provider>;
};
export const useThemeContext = () => {
	return useContext<ThemeContextProps>(ThemeContext);
};
