import { FC, PropsWithChildren } from "react";
import { useAccordionItemContext } from "../../provider/AccordionItemProvider";
import { useAccordionContext } from "../../provider/AccordionProvider";
import { Icon } from "../Icon";
import styles from "./AccordionItemHeading.module.scss";
export const AccordionItemHeading: FC<PropsWithChildren> = ({ children }) => {
	const { id, isActive } = useAccordionItemContext();
	const { changeActiveItem } = useAccordionContext();
	return (
		<button onClick={() => changeActiveItem(id)} className={styles.heading}>
			{children}
			<Icon icon={"arrowDown"} className={styles.icon} data-open={isActive} />
		</button>
	);
};
