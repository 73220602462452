import classNames from "classnames";
import { ComponentProps, FC } from "react";
import styles from "./GridRow.module.scss";
interface GridRowProps extends ComponentProps<"div"> {}
export const GridRow: FC<GridRowProps> = ({ className, children, ...rest }) => {
	let _className = classNames(className, styles.gridRow);
	return (
		<div className={_className} {...rest}>
			{children}
		</div>
	);
};
