import classNames from "classnames";
import { ComponentProps, forwardRef } from "react";
import styles from "./Paragraph.module.scss";

type ParagraphProps = ComponentProps<"p"> & {
	size?: "lg" | null;
	renderedDirect?: boolean;
	textCenter?: boolean;
};

export const Paragraph = forwardRef<HTMLParagraphElement, ParagraphProps>(
	({ size, className, textCenter, renderedDirect, children, ...rest }, ref) => {
		let _classNames = classNames(className, {
			[styles.lg]: size === "lg",
			[styles.textCenter]: textCenter,
		});
		if (renderedDirect) {
			return <div {...rest}>{children}</div>;
		}
		return (
			<p ref={ref} className={_classNames} {...rest}>
				{children}
			</p>
		);
	}
);
