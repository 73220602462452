import { FC, PropsWithChildren } from "react";
import { AccordionContextProvider } from "../../provider/AccordionProvider";
import styles from "./Accordion.module.scss";
interface AccordionProps {
	multiple?: boolean;
}

export const Accordion: FC<PropsWithChildren<AccordionProps>> = ({ children, multiple = false }) => {
	return (
		<AccordionContextProvider multiple={multiple}>
			<div className={styles.accordion}>{children}</div>
		</AccordionContextProvider>
	);
};
