import { AnalyticsContainer } from "analytics";
import { SeoConfig } from "config/seoConfig";
import { HeadGlobal } from "global/Head.global";
import { ScriptsGlobal } from "global/Scripts.global";
import { DefaultSeo } from "next-seo";
import type { AppProps } from "next/app";

import { Footer, Header, Main } from "ui";
import { UiProvider } from "ui/src/provider/UiProvider";
import "ui/src/styles/styles.scss";

function MyApp({ Component, pageProps }: AppProps) {
	const NextComponent = Component as any;
	if (process.env.NODE_ENV === "production") {
		console.log = function () {};
	}
	return (
		<>
			<AnalyticsContainer analyticsId={process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}>
				<UiProvider>
					{process.env.NODE_ENV !== "development" && <ScriptsGlobal />}
					<Header />
					<DefaultSeo {...SeoConfig} />
					<Main>
						<NextComponent {...pageProps} />
					</Main>
					<Footer />
					<HeadGlobal />
				</UiProvider>
			</AnalyticsContainer>
		</>
	);
}
export default MyApp;
