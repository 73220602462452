import { NextSeoProps } from "next-seo/lib/types";
import { getOgImageUrl } from "seo";

export const SeoConfig: NextSeoProps = {
	titleTemplate: "%s - Patrick Dittrich",
	defaultTitle: "Patrick Dittrich",
	title: "Patrick Dittrich",
	openGraph: {
		type: "www",
		locale: "en_IE",
		url: process.env.NEXT_PUBLIC_BASE_URL,
		description: "Patrick Dittrich - Webdeveloper based in bayreuth germany",
		site_name: "Patrick Dittrich",
		images: [
			{
				url: getOgImageUrl({
					title: "Patrick Dittrich",
					content: "Patrick Dittrich - Webdeveloper based in bayreuth germany",
				}),
				width: 1200,
				height: 630,
				alt: "www.patrickdittrich.com",
				type: "image/jpeg",
			},
		],
	},
	twitter: {
		handle: "@handle",
		site: "@site",
		cardType: "summary_large_image",
	},
};
