import { ComponentProps, FC } from "react";

interface HeadlineProps extends ComponentProps<"h1"> {
	level?: 1 | 2 | 3 | 4 | 5 | 6;
	size?: "xxl" | "xl" | "l";
}

export const Headline: FC<HeadlineProps> = ({ children, level = 3, size = "xl" }) => {
	const Level = ("h" + level) as keyof JSX.IntrinsicElements;
	return <Level>{children}</Level>;
};
