import Image from "next/legacy/image";
import Link from "next/link";
import { FC } from "react";
import styles from "./Card.module.scss";
interface CardProps {
	title?: string;
	subTitle?: string;
	href: string;
	headerLabel?: string;
	imageSrc?: string;
	imageAlt?: string;
}
export const Card: FC<CardProps> = ({ headerLabel, href, subTitle, title, imageAlt, imageSrc }) => {
	return (
		<Link href={href} passHref={true} className={styles.card}>
			<Image
				className={styles.image}
				src={imageSrc}
				alt={imageAlt}
				width={1600}
				height={900}
				objectFit={"cover"}
				layout={"responsive"}
			/>
			<div className={styles.content}>
				{headerLabel && <h4 className={styles.headerLabel}>{headerLabel.toUpperCase()}</h4>}
				{title && <h3 className={styles.title}>{title}</h3>}
				{subTitle && <h4 className={styles.subTitle}>{subTitle}</h4>}
			</div>
		</Link>
	);
};
